import dayjs from "dayjs"
import { action, makeAutoObservable, reaction, runInAction } from "mobx"
import ApiService from "../../api/ApiService"
import AuthStore from "./AuthStore"
import OrgUnitStore from "./OrgUnitStore"
import UserNotificationStore from "./UserNotificationStore"
import getConfig from "../../../../env"

export interface ZsbSorterConfig {
  serialNo: string
  label: string
  deviceType: string
  orgId: string
  appChannel?: string
  settings: { [key: string]: ZsbSorterSetting }
}

export type ZsbSorterSettingKey =
  | "SCAN_MODE"
  | "ENFORCE_VERIFY"
  | "SORT_FOR_DATE"
  | "LABEL_PRINT_ENABLED"
  | "APP_CHANNEL"

export interface ZsbSorterSetting {
  key: ZsbSorterSettingKey
  value: any
  selfControlled: boolean
}
export interface Option {
  key?: string
  label: string
}
export interface Options {
  key: ZsbSorterSettingKey
  options: Option[]
  default: string
}

export default class ZsbSorterConfigStore {
  authStore: AuthStore
  apiService: ApiService
  orgUnitStore: OrgUnitStore
  userNotificationStore: UserNotificationStore

  configurations: ZsbSorterConfig[] = []
  defaultConfig: ZsbSorterConfig | undefined = undefined
  configurationsLoading = false
  selectedConfigurationIds = new Set<string>([])
  searchString = ""
  showDetails = false
  isUpdateRequestPending = false

  defaultScanModeOptions(): Options {
    return {
      key: "SCAN_MODE",
      options: [
        { key: "SELF_CONTROLLED", label: "Wird am Gerät eingestellt" },
        { key: "SORTING", label: "Sortierung" },
        { key: "SE_SCAN", label: "Sendungseingang" },
        { key: "DEMO", label: "DEMO-Modus" },
      ],
      default: "SORTING",
    }
  }

  get scanModeOptions(): Options {
    const options = this.defaultScanModeOptions()
    if (this.selectedConfigurations?.every((config) => config.settings["LABEL_PRINT_ENABLED"]?.value)) {
      options.options.push({ key: "LABEL_PRINT", label: "Labeldruck" })
    }

    return options
  }

  get appChannelOptions(): Options {
    return {
      key: "APP_CHANNEL",
      options: [
        { key: "production", label: "Produktion" },
        { key: "canary", label: "Canary" },
        { key: "develop", label: "Staging" },
      ],
      default: getConfig().appChannel,
    }
  }

  get enforceVerifyOptions(): Options {
    return {
      key: "ENFORCE_VERIFY",
      options: [
        { key: "SELF_CONTROLLED", label: "Wird am Gerät eingestellt" },
        { key: "DISABLED", label: "Sortierziel muss nicht gescannt werden" },
        { key: "ENFORCE_SCAN", label: "Beliebiges Sortierziel muss gescannt werden" },
        { key: "ENFORCE_CORRECT_SCAN", label: "Richtiges Sortierziel muss gescannt werden" },
      ],
      default: "DISABLED",
    }
  }

  get selectedOrgId() {
    return this.orgUnitStore.selectedOrgUnit?.orgId
  }

  get selectedConfigurations() {
    return this.configurations.filter((it) => this.selectedConfigurationIds.has(it.serialNo))
  }

  get doAllSelectedHaveDefaultConfigsOnly() {
    // checks if every settings key of selected configs is empty
    return this.selectedConfigurations.every(
      (config) =>
        config.settings &&
        Object.keys(config.settings).length === 0 &&
        Object.getPrototypeOf(config.settings) === Object.prototype
    )
  }

  setSearchString = (next: string) => (this.searchString = next)

  openDetails = () => {
    this.showDetails = true
  }

  hideDetails = () => {
    this.showDetails = false
  }

  selectConfigurations = (configurations: ZsbSorterConfig[]) => {
    this.selectedConfigurationIds = new Set(configurations.map((it) => it.serialNo))
  }

  changeSelection = (configuration: ZsbSorterConfig, select: boolean) => {
    const selected = this.selectedConfigurationIds.has(configuration.serialNo)
    if (select && !selected) {
      this.selectedConfigurationIds.add(configuration.serialNo)
    } else if (!select && selected) {
      this.selectedConfigurationIds.delete(configuration.serialNo)
    }
  }

  updateConfigurations = async (configs: ZsbSorterConfig[]) => {
    configs.forEach((config) => {
      const index = this.configurations.findIndex((it) => it.serialNo === config.serialNo)
      // check if LABEL_PRINT_ENABLED is switch off, but LABEL_PRINT mode still selected as SCAN_MODE
      if (!config.settings["LABEL_PRINT_ENABLED"]?.value) {
        const scanMode = config.settings["SCAN_MODE"]
        if (scanMode?.value === "LABEL_PRINT") {
          scanMode.value = "SELF_CONTROLLED"
          scanMode.selfControlled = true
        }
      }
      if (index === -1) this.configurations.push(config)
      else this.configurations[index] = config
    })

    this.isUpdateRequestPending = true

    try {
      await this.apiService.post(`v1/zsbSorterConfig/${this.selectedOrgId}`, configs)
    } catch (response: any) {
      console.log("ZsbSorterConfigStore.updateConfigurations failed to update configurations: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "ZsbSorterConfigStore",
        response,
        "Aktualisieren der Einstellungen fehlgeschlagen",
        "blocking"
      )
      return response.ok
    } finally {
      await this.fetchConfigurations()
      runInAction(() => (this.isUpdateRequestPending = false))
    }
  }

  updateDefaultConfiguration = async (config: ZsbSorterConfig[]) => {
    this.defaultConfig = config.find((config) => config.serialNo === "zsb-sorter-default-config")

    this.isUpdateRequestPending = true

    try {
      await this.apiService.post(`v1/zsbSorterConfig/${this.selectedOrgId}`, config)
    } catch (response: any) {
      console.log("ZsbSorterConfigStore.updateConfigurations failed to update defaultConfig: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "ZsbSorterConfigStore",
        response,
        "Aktualisieren der Standardeinstellungen fehlgeschlagen",
        "blocking"
      )
      return response.ok
    } finally {
      await this.fetchDefaultConfiguration()
      runInAction(() => (this.isUpdateRequestPending = false))
    }
  }

  deleteConfigurations = async (configs: ZsbSorterConfig[]) => {
    const serialNumbers = configs.map((config) => config.serialNo)

    try {
      await this.apiService.delete(`v1/zsbSorterConfig/${this.selectedOrgId}`, serialNumbers)
      this.userNotificationStore.resolveApiResponseError("ZsbSorterConfigStore")
    } catch (response: any) {
      console.log("OrgUnitStore.deleteConfigurations failed to delete configurations: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "ZsbSorterConfigStore",
        response,
        "Zurücksetzen auf Standardeinstellungen fehlgeschlagen",
        "blocking"
      )
      return response.ok
    } finally {
      this.fetchConfigurations()
    }
  }

  getBySortMode = (sortMode: string | undefined): ZsbSorterConfig[] => {
    return (
      this.configurations.filter(
        (it) =>
          (it.settings.SCAN_MODE ? it.settings.SCAN_MODE.value : this.defaultConfig?.settings.SCAN_MODE?.value) ===
          sortMode
      ) ?? []
    )
  }

  getBySortForDate = (sortForDate: "CURRENT" | "NEXT" | undefined): ZsbSorterConfig[] => {
    const localTime = dayjs().locale("de")

    const isSortDate = (
      setting: ZsbSorterSetting | undefined,
      sortForDate: string | undefined
    ): boolean | undefined => {
      if (!setting || !sortForDate) return undefined
      return (
        setting.value === sortForDate ||
        (setting.value.indexOf(":") !== -1 &&
          (sortForDate == "CURRENT"
            ? localTime.isBefore(dayjs(setting.value, "H:mm"))
            : localTime.isAfter(dayjs(setting.value, "H:mm"))))
      )
    }

    return this.configurations.filter((it) => {
      const scannerSettingsSortDateDate = isSortDate(it.settings.SORT_FOR_DATE, sortForDate)
      if (scannerSettingsSortDateDate === undefined) {
        return !!isSortDate(this.defaultConfig?.settings.SORT_FOR_DATE, sortForDate)
      } else {
        return scannerSettingsSortDateDate
      }
    })
  }

  async fetchConfigurations() {
    this.configurationsLoading = true

    try {
      const response = await this.apiService.get(`v1/zsbSorterConfig/${this.selectedOrgId}`)
      if (response.ok) {
        const configurations = await response.json()
        runInAction(() => {
          this.configurations = configurations
        })
      }
      this.userNotificationStore.resolveApiResponseError("ZsbSorterConfigStore")
    } catch (response: any) {
      console.log("ZsbSorterConfigStore.fetchConfigurations failed to fetch configurations: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "ZsbSorterConfigStore",
        response,
        "Laden der Sorter-Einstellungen fehlgeschlagen",
        "blocking",
        {
          callback: action(() => this.fetchConfigurations()),
        }
      )
      return response.ok
    } finally {
      runInAction(() => (this.configurationsLoading = false))
    }
  }

  async fetchDefaultConfiguration() {
    try {
      const response = await this.apiService.get(`v1/zsbSorterConfig/${this.selectedOrgId}/defaultConfig`)
      if (response.ok) {
        const defaultConfig = await response.json()
        runInAction(() => {
          this.defaultConfig = defaultConfig
        })
      }
      this.userNotificationStore.resolveApiResponseError("ZsbSorterConfigStore")
    } catch (response: any) {
      console.log("ZsbSorterConfigStore.fetchDefaultConfigurations failed to fetch defaultConfig: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "ZsbSorterConfigStore",
        response,
        "Laden der Standard-Sorter-Einstellungen fehlgeschlagen",
        "blocking",
        {
          callback: action(() => this.fetchDefaultConfiguration()),
        }
      )
      return response.ok
    }
  }

  constructor(
    authStore: AuthStore,
    apiService: ApiService,
    orgUnitStore: OrgUnitStore,
    userNotificationStore: UserNotificationStore
  ) {
    this.authStore = authStore
    this.apiService = apiService
    this.orgUnitStore = orgUnitStore
    this.userNotificationStore = userNotificationStore
    makeAutoObservable(this)

    let fetchInterval: ReturnType<typeof setTimeout>

    reaction(
      () => ({ authToken: this.authStore.authToken, selectedOrgUnit: this.orgUnitStore.selectedOrgUnit }),
      () => {
        clearInterval(fetchInterval)
        if (this.authStore.authToken && this.orgUnitStore.selectedOrgUnit) {
          this.fetchConfigurations()
          this.fetchDefaultConfiguration()

          fetchInterval = setInterval(() => {
            this.fetchConfigurations()
            this.fetchDefaultConfiguration()
          }, 10_000)
        } else clearInterval(fetchInterval)
      }
    )
  }
}
