import { navigate } from "@reach/router"
import { action, makeAutoObservable, reaction, runInAction } from "mobx"
import ApiService from "../../api/ApiService"
import AuthStore from "./AuthStore"
import UserNotificationStore from "./UserNotificationStore"

export interface OrgUnit {
  id: string
  orgId: string
  name: string
  canaryApplications: string[]
  features?: { [key: string]: boolean }
}

export default class OrgUnitStore {
  authStore: AuthStore
  apiService: ApiService
  userNotificationStore: UserNotificationStore

  orgUnits: OrgUnit[] = []
  initialOrgUnit = localStorage.getItem("common/orgunit/selectedOrgUnitId")
  selectedOrgUnit?: OrgUnit = undefined

  get orgUnitAccess() {
    return this.authStore.userInfo?.orgUnitAccess
  }

  get hasWritePermission() {
    const orgId = this.selectedOrgUnit?.orgId
    if (!orgId) return false

    return !!(
      this.authStore.userInfo?.orgUnitAccess?.find(
        (i) => (i.orgId === orgId || i.orgId === "*") && !!i.accessType?.find((r) => r === "full")
      ) || false
    )
  }

  onSelectOrgUnit = (orgUnitId: string) => {
    const selectedOrgUnit = this.findById(orgUnitId)
    if (this.orgUnits.length !== 0 && !selectedOrgUnit) {
      this.userNotificationStore.showInfoModal(
        "Fehlende Berechtigung oder ungültige Organisation",
        `Bitte überprüfen Sie, ob Sie Rechte zum Zugriff auf Organisation \
${orgUnitId} haben und ob für die Organisation ein Cockpit eingerichtet ist.`,
        `The current user "${this.authStore.userInfo?.username}" \
doesn't have the Org: "${orgUnitId}" assigned as a Group on Keycloak. \n \n`,
        {
          name: "App Neustarten",
          callback: () => {
            navigate("/")
            window.location.reload()
          },
        }
      )
    } else if (selectedOrgUnit) {
      this.selectedOrgUnit = selectedOrgUnit
      navigate(`/orgs/${selectedOrgUnit.orgId}`)
      console.log("selecting org unit")
      localStorage.setItem("common/orgunit/selectedOrgUnitId", selectedOrgUnit.orgId)
    }
  }

  findById = (orgId: string) => {
    return this.orgUnits?.find((item) => item.orgId === orgId)
  }

  async fetchOrgUnits(orgIds: string[]) {
    try {
      const response = await this.apiService.get(`v1/orgUnits?orgIds=${orgIds.join(`,`)}`)
      if (response.ok) {
        const fetchedOrgUnits = await response.json()
        runInAction(() => (this.orgUnits = fetchedOrgUnits))
        const urlPathName = window.location.pathname
        const urlOrgId = getURLPathParam("orgs", urlPathName)
        if (urlOrgId) {
          this.onSelectOrgUnit(urlOrgId)
        } else if (!this.selectedOrgUnit && this.orgUnits.length) {
          const selectedOrgUnitId = (this.findById(this.initialOrgUnit || "") || this.orgUnits?.[0])?.orgId
          navigate(`/orgs/${selectedOrgUnitId}`)
        }
      }
      this.userNotificationStore.resolveApiResponseError("OrgUnitStore")
    } catch (response: any) {
      console.log("OrgUnitStore.fetchOrgUnits failed to fetch Org Units: ", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "OrgUnitStore",
        response,
        "Laden der Organisationsliste fehlgeschlagen",
        "blocking",
        {
          callback: action(() => this.fetchOrgUnits(orgIds)),
        }
      )

      return response.ok
    }
  }

  constructor(authStore: AuthStore, apiService: ApiService, userNotificationStore: UserNotificationStore) {
    this.authStore = authStore
    this.apiService = apiService
    this.userNotificationStore = userNotificationStore

    makeAutoObservable(this)

    reaction(
      () => this.authStore.authToken,
      () => {
        if (this.authStore.authToken && this.orgUnitAccess?.length) {
          this.fetchOrgUnits(this.orgUnitAccess.map((o) => o.orgId))
        }
      }
    )
  }
}

function getURLPathParam(param: string, pathname: string) {
  const indexofParam = pathname.indexOf(param)
  if (indexofParam === -1) return undefined
  let result = pathname.substring(indexofParam + param.length + 1)
  const indexRemainingSlash = result.indexOf("/")
  const restOfPath = indexRemainingSlash !== -1 ? result.substring(indexRemainingSlash) : ""
  result = result.replace(restOfPath, "")
  return result
}
