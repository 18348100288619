import "./index.css"
import App from "./App"
import getConfig from "./env"
import { createRoot } from "react-dom/client"

const root = createRoot(document.getElementById("root")!)
root.render(<App />)

// Changing page title if not production to make it easier to navigate between tabs
if (getConfig().appChannel !== "production")
  document.title = `${getConfig().appChannel.charAt(0).toUpperCase() + getConfig().appChannel.slice(1)} | ${
    document.title
  }`
